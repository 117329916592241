import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet, UnderlineLink } from '~styles/global'
import { useEffect } from 'react'
import canUseDOM from '~utils/canUseDOM'
import '~styles/flickity.css'
import '~styles/flickity-fade.css'
// import '~styles/flickity-fade.js'
import { useState } from 'react'
import { useRef } from 'react'
import Section from '~components/Section'
import Media from '~components/Media'
import SanityLink from '~components/SanityLink'

const HeaderSliderMobile = ({ className, slides }) => {

	const [init, setInit] = useState()
	const [integer, setInteger] = useState(0)
	const [initial, setInitial] = useState(undefined)
	const [interacted, setInteracted] = useState(false)

	useEffect(() => {
		setInitial(window.innerHeight)
	}, [])	

	const flkty = useRef()
	const flickityEl = useRef()

	useEffect(() => {
		if (canUseDOM && init){
			const Flickity = require('flickity')
			flkty.current = new Flickity(flickityEl.current, flickityOptions)
		}
		setInit(true)
	}, [init])

	useEffect(() => {
		if (init) {
			flkty?.current?.on('dragEnd', () => {
				setInteger(flkty?.current?.selectedIndex)
			} )
		}
	}, [init])

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: true,
		cellAlign: 'left',
		freeScroll: false,
		contain: true,
		fade: true
	}

	useEffect(() => {

		const interval = setInterval(() => {
			if (integer >= (slides?.length - 1)) {
				setInteger(0)
			} else {
				setInteger(integer => integer + 1)
			}
		}, 4500)
		return () => clearInterval(interval)

	}, [integer])

	useEffect(() => {
		if (init) {
			flkty?.current?.select(integer)
		}
	}, [integer, init])

	return (
		<>
			<Global styles={css`
			:root {
				--headerColor: ${slides[integer]?.textColorDesktop};
			}
		`}/>
			<Wrap className={className} initial={initial}>
				<Flickity ref={flickityEl}>
					{slides.map((slide, i) => (
						<Slide 
							initial={initial}
							key={slide?.heading} 
							active={i === integer}
							link={slide?.button?.link}
						>
							<StyledMedia 
								media={slide?.media} 
								cover 
								initial={initial}
								active={i === integer}/>
						</Slide>
					))}
				</Flickity>
				{/* <Images>
				{slides.map((slide, i) => (
					<StyledMedia 
						media={slide?.media} 
						cover 
						initial={initial}
						key={slide?.heading} 
						active={i === integer}/>
				))}
			</Images> */}
				<Content>
					<SlideContentRelative>
						{slides.map((slide, i) => (
							<SlideContent 
								key={slide?.heading} 
								active={i === integer}
								color={slide?.textColorDesktop}
								colorMob={slide?.textColorMobile}
							>
								<SlideContentContainer>
									<Heading>{slide?.heading}</Heading>
									<SubHeading className='h2'>{slide?.subheading}</SubHeading>
									<UnderlineLink 
										className='h2' 
										link={slide.button.link} 
										white={slide?.textColorDesktop === 'white'} 
										whiteMob={slide?.textColorMobile === 'white'}
									>
										{slide.button.text}
									</UnderlineLink>
								</SlideContentContainer>
							</SlideContent>
						))}
					</SlideContentRelative>
				</Content>
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	display: none;
	${mobile}{
		display: block;
		position: relative;
		height: ${props => `${props.initial}px`};
	}
`
const Slide = styled(SanityLink)`
	width: 100%;
	height: ${props => `${props.initial}px`};
`
const Flickity = styled.div`
`
const StyledMedia = styled(Media)`
	line-height: 0;
	/* opacity: ${ props => props.active ? '1' : '0'}; */
	/* transition: opacity 0.25s; */
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: ${props => `${props.initial}px`};
	> div{
		height: ${props => `${props.initial}px`};
	}
`
const Content = styled.div`
	pointer-events: none;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 2;
`
const SlideContentRelative = styled.div`	
	position: relative;
	height: 100%;
`
const SlideContent = styled(Section)`
	position: absolute;
	opacity: ${ props => props.active ? '1' : '0'};
	pointer-events: ${ props => props.active ? 'all' : 'none'};	
	transition: opacity 0.25s;
	bottom: 42px;
	left: 0;
	right: 0;
	color: ${props => props.color};
	${mobile}{
		color: ${props => props.color};
		bottom: 25px;
	}
`
const SlideContentContainer = styled.div`
	grid-column: span 12;
`
const Heading = styled.h2`
	margin-bottom: 2px;
	${mobile}{
		margin-bottom: 0;
	}
`
const SubHeading = styled.h2`
	margin-bottom: 9px;
`


HeaderSliderMobile.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.array,
}

export default HeaderSliderMobile