import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import HeaderSlider from '~components/HeaderSlider'
import RichText from '~components/RichText'
import SanityLink from '~components/SanityLink'
import { mobile } from '~styles/global'
import VideoPlayer from '~components/VideoPlayer'
import ProjectTile from '~components/ProjectTile'
import BorderButton from '~components/BorderButton'
import HeaderSliderMobile from '~components/HeaderSliderMobile'
import useBreakpoint from '~utils/useBreakpoint'

const IndexPage = ({ data }) => {
	const { page } = data
	const { isMobile } = useBreakpoint()

	return (
		<>	
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			{isMobile ?
				<HeaderSliderMobile slides={page?.slider}/>
				:
				<HeaderSlider slides={page?.slider}/>
			}
			<Section>
				<Intro>
					<IntroText className='h1'>
						<RichText content={page?.introduction}/>
					</IntroText>
					{page?.introductionButton?.lin && 
						<IntroLink link={page?.introductionButton?.link}>
							{page?.introductionButton?.text}
						</IntroLink>
					}
				</Intro>
			</Section>
			<Showreel 
				desktopVideo={page?.generalShowreelVimeo?.desktopVideo ?? page?.generalShowreel?.desktopVideo?.asset?.url} 
				mobileVideo={page?.generalShowreelVimeo?.mobileVideo ?? page?.generalShowreel?.mobileVideo?.asset?.url}
				soundToggle={page?.generalShowreel?.showMuteToggle}
				loop={true}
			/>
			{page?.firstTextModule && 
				<Section>
					<Intro className='h1'>
						<RichText content={page?.firstTextModule}/>
					</Intro>
				</Section>
			}
			<FeaturedProjects>
				{page?.featuredProjects?.map(project => (
					<ProjectTile key={project.title} project={project} home />
				))}
			</FeaturedProjects>
			{page?.secondTextModule &&
				<>
					<Section>
						<Text className='h1'>
							<RichText content={page?.secondTextModule}/>
						</Text>
					</Section>
					<ViewAllProjects 
						link={page.allProjectsButton?.link}
					>
						{page.allProjectsButton?.text}
					</ViewAllProjects>
				</>
			}
			<Showreel 
				desktopVideo={page?.digitalShowreelVimeo?.desktopVideo ?? page?.digitalShowreel?.desktopVideo?.asset?.url} 
				mobileVideo={page?.digitalShowreelVimeo?.mobileVideo ?? page?.digitalShowreel?.mobileVideo?.asset?.url}
				loop={true}
			/>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Intro = styled.div`
	grid-column: span 12;
	text-align: center;
	max-width: 800px;
	margin: 0 auto;
	margin-top: 171px;
	margin-bottom: 171px;
	${mobile}{
		margin-top: 108px;
		margin-bottom: 108px;
	}
`
const IntroText = styled.div`

`
const IntroLink = styled(SanityLink)`
	margin-top: 62px;
	${mobile}{
		margin-top: 90px;
	}
`
const Showreel = styled(VideoPlayer)`
`
const FeaturedProjects = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	margin-bottom: 171px;
	${mobile}{
		grid-template-columns: 1fr;
		margin-bottom: 0;
	}
`

const Text = styled.div`
	grid-column: span 12;
	max-width: 800px;
	margin: 0 auto 86px;
	text-align: center;
	${mobile}{
		margin-top: 70px;
		margin-bottom: 70px;
	}
`
const ViewAllProjects = styled(BorderButton)`
	margin: 0 auto 171px;
	${mobile}{
		margin-bottom: 108px;
	}
`

export const query = graphql`
  query HomeQuery {
    page: sanityHomePage {
      title
			navColor
			slider {
				textColorDesktop
				textColorMobile
				heading
				subheading
				button {
					text
					link {
						...link
					}
				}
				media {
					...media
				}
			}
			introduction: _rawIntroduction
			introductionButton {
				text
				link {
					...link
				}
			}
			generalShowreel {
				...videoPlayer
			}
			generalShowreelVimeo {
				desktopVideo
				mobileVideo
			}
			firstTextModule: _rawFirstTextModule
			featuredProjects {
				...projectThumb
			}
			allProjectsButton {
				text
				link {
					...link
				}
			}
			secondTextModule: _rawSecondTextModule
			digitalShowreel {
				...videoPlayer
			}
			digitalShowreelVimeo {
				desktopVideo
				mobileVideo
			}
			seo{
				...seo
			}
    }
  }
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export default IndexPage
