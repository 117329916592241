import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, UnderlineLink } from '~styles/global'
import Media from '~components/Media'
import Section from '~components/Section'
import SanityLink from '~components/SanityLink'


const HeaderSlider = ({ className, slides }) => {
	const [integer, setInteger] = useState(0)
	const slideLength = slides?.length
	const [initial, setInitial] = useState(undefined)
	const [interacted, setInteracted] = useState(false)

	useEffect(() => {
		setInitial(window.innerHeight)
	}, [])	

	const nextSlide = ()=> {
		setInteracted(true)
		if (integer >= slideLength -1) {
			setInteger(0)
		} else {
			setInteger(integer + 1)
		}
	}
	const prevSlide = ()=> {
		setInteracted(true)
		if (integer === 0) {
			setInteger(slideLength -1)
		} else {
			setInteger(integer - 1)
		}
	}

	useEffect(() => {
		if(interacted){
			return 
		} else {
			const interval = setInterval(() => {
				if (integer >= (slides?.length - 1)) {
					setInteger(0)
				} else {
					setInteger(integer => integer + 1)
				}
			}, 3000)
			return () => clearInterval(interval)
		}

	}, [integer, interacted])

	return (
		<>
			<Global styles={css`
				:root {
					--headerColor: ${slides[integer]?.textColorDesktop};
				}
			`}/>
			<Wrap className={className} initial={initial}>
				{slides.map((slide, i) => (
					<Slide 
						initial={initial}
						key={slide?.heading} 
						active={i === integer}
						link={slide?.button?.link}
					>
						<StyledMedia media={slide?.media} cover initial={initial} hiRes/>
					</Slide>
				))}
				<Content>
					<SlideContentRelative>
						{slides.map((slide, i) => (
							<SlideContent 
								key={slide?.heading} 
								color={slide?.textColorDesktop}
								colorMob={slide?.textColorMobile}
							>
								<SlideContentContainer active={i === integer}>
									<Heading>{slide?.heading}</Heading>
									<SubHeading className='h2'>{slide?.subheading}</SubHeading>
									<UnderlineLink 
										className='h2' 
										link={slide?.button?.link} 
										white={slide?.textColorDesktop === 'white'} 
										whiteMob={slide?.textColorMobile === 'white'}
									>
										{slide.button.text}
									</UnderlineLink>
								</SlideContentContainer>
							</SlideContent>
						))}
					</SlideContentRelative>
				</Content>
				<Buttons>
					<Left onClick={() => prevSlide()} white={slides[integer]?.textColorDesktop === 'white'} />
					<Right onClick={() => nextSlide()} white={slides[integer]?.textColorDesktop === 'white'} />
				</Buttons>
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	position: relative;
	height: 100vh;
	${mobile}{
		display: none;
	}
`
const Slide = styled(SanityLink)`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	opacity: ${ props => props.active ? '1' : '0'};
	transition: opacity 0.6s;
	transition-delay: ${ props => props.active ? '0' : '0.3s'};
	height: 100vh;
	overflow: hidden;
	display: block;
	z-index: ${ props => props.active ? '5' : '1'};
	/* pointer-events: ${ props => props.active && !props.panning ? 'all' : 'none'}; */
	margin-right: 0;
	${mobile}{
		height: ${props => `${props.initial}px`};
	}
	video{
		height: 100vh;
		${mobile}{
			height: ${props => `${props.initial}px`};
		}
	}
`
const StyledMedia = styled(Media)`
	line-height: 0;
	height: 100vh;
	> div{
		height: 100vh;
	}
`
const Content = styled.div`
	pointer-events: none;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 11;
`
const SlideContentRelative = styled.div`	
	position: relative;
	height: 100%;
`
const SlideContent = styled(Section)`
	position: absolute;
	bottom: 42px;
	left: 0;
	right: 0;
	color: ${props => props.color};
	${mobile}{
		color: ${props => props.color};
		bottom: 25px;
	}
`
const SlideContentContainer = styled.div`
	opacity: ${ props => props.active ? '1' : '0'};
	pointer-events: ${ props => props.active ? 'all' : 'none'};	
	transition: opacity 0.25s;
	grid-column: span 6;
	${mobile}{
		grid-column: span 12;
	}
`
const Heading = styled.h2`
	margin-bottom: 2px;
	${mobile}{
		margin-bottom: 0;
	}
`
const SubHeading = styled.h2`
	margin-bottom: 9px;
`
const Buttons = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	z-index: 10;
	${mobile}{
		display: none;
	}
`
const Left = styled.div`
	width: 33.33%;
	cursor: ${ props => props.white ? 'url("/images/LarrWhite.png"), auto' : 'url("/images/Larr.png"), auto'};
	cursor: ${ props => props.white ? '-webkit-image-set(url("/images/LarrWhite.png") 1x, url("/images/Larr@2x.png") 2x) 0 0, pointer' : '-webkit-image-set(url("/images/Larr.png") 1x, url("/images/Larr@2x.png") 2x) 0 0, pointer'}; 
	pointer-events: all;
`
const Right = styled.div`
	width: 66.66%;
	cursor: ${ props => props.white ? 'url("/images/RarrWhite.png"), auto' : 'url("/images/Rarr.png"), auto'};
	cursor: ${ props => props.white ? '-webkit-image-set(url("/images/RarrWhite.png") 1x, url("/images/Rarr@2x.png") 2x) 0 0, pointer' : '-webkit-image-set(url("/images/Rarr.png") 1x, url("/images/Rarr@2x.png") 2x) 0 0, pointer'}; 
	pointer-events: all;
`

HeaderSlider.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.array,
}

export default HeaderSlider